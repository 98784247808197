import React, { useState } from "react";
import { CircularProgress } from "@mui/material";
import { useRecoilState } from "recoil";
import { loadingAtom } from "../../atoms/atoms";

const Loader = (props) => {
	const [loading, setLoading] = useRecoilState(loadingAtom);

	return (
		<>
			<div className="loader" style={{ display: loading ? "flex" : "none" }}>
				<CircularProgress />
			</div>
		</>
	);
};

export default Loader;
